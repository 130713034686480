import { useIsAuthenticated } from "~/providers/Auth";

import withConditionalRedirect, { AuthHoc } from "./withConditionalRedirect";

/**
 * Require the user to be unauthenticated in order to render the component.
 * If the user is authenticated, forward to the given URL.
 */
const withoutAuth: AuthHoc = (WrappedComponent, location = "/") => {
  return withConditionalRedirect({
    WrappedComponent,
    location,
    clientCondition: function withoutAuthClientCondition() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useIsAuthenticated();
    },
    serverCondition: function withoutAuthServerCondition(ctx) {
      return !!(ctx.req as any)?.cookies?.sessionid;
    },
  });
};
export default withoutAuth;
