import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { GoogleLogo } from "~/components/icons";
import { Footer, Logo } from "~/components/ui";
import { Button, Input, Label } from "~/components/ui/forms";
import withoutAuth from "~/hocs/withoutAuth";
import { fetchWrapper } from "~/utils/fetchWrapper";

type SignInForm = {
  username: string;
  password: string;
};

function SignIn() {
  const { handleSubmit, register } = useForm<SignInForm>();
  const [error, setError] = useState("");
  const router = useRouter();

  async function login(loginInfo: SignInForm) {
    try {
      const user = await fetchWrapper.post("/api/v1/user/login", loginInfo);

      if (user) {
        router.reload();
      }
    } catch (e: any) {
      setError(e["non_field_errors"]);
    }
  }

  return (
    <main className="h-screen">
      <div className="mx-auto flex h-full max-w-lg flex-col content-between justify-between">
        <div className="py-8 text-center text-2xl font-bold no-underline hover:no-underline md:text-4xl">
          <Logo className="size-8">My Money</Logo>
          <p className="text-lg font-normal">Your personal finance manager</p>
        </div>

        <div className="grow ">
          {error && <p className="border bg-red-200 p-4">{error}</p>}

          <div className="rounded-xl p-8 text-center text-gray-600 md:p-12 lg:shadow-xl">
            <form onSubmit={handleSubmit(login)} className="p-4 text-left">
              <Label>
                Username
                <Input {...register("username")} autoComplete="on" required />
              </Label>
              <Label>
                Password
                <Input {...register("password")} type="password" autoComplete="on" required />
              </Label>
              <Button type="submit" className="mt-2">
                Login
              </Button>
            </form>

            <Button
              type="button"
              onClick={() => (window.location.href = "/api/v1/oauth2/login/google-oauth2")}
            >
              <GoogleLogo className="mr-2"></GoogleLogo> Sign in with Google
            </Button>

            <div className="pt-1 text-center text-sm">
              <span className="mr-2 text-accent-7">Don&apos;t have an account?</span>
              <Link
                href="/signup"
                className="cursor-pointer font-bold text-accent-9 hover:underline"
              >
                Sign Up
              </Link>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </main>
  );
}

export default withoutAuth(SignIn);
